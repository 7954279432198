import React, { useState } from "react";
import Header from "../../layouts/Header";
import goo from "/images/goo.webp";
import fetchApi from "../../services/query/query";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const ForgetPassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ email: "" });
  const navigate = useNavigate();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetchApi("POST", "forgot-password", formData);
      console.log(response);

      if (response?.message === "User doesn't exist") {
        toast.error(response.message);
      } else {
        toast.success(response.message);
        navigate("/auth/reset-password");
      }
    } catch (error) {
      console.error("Error occurred", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="min-h-screen "
      style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${goo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className=" flex flex-col items-center justify-center px-8 py-12">
        <Header Sign_log="login" isStatic={false} />
        <div className="max-w-md w-full mt-[200px]  p-6 bg-white rounded-md shadow-md">
          <h2 className="text-2xl font-semibold mb-6 text-center m-auto">
            Forget Password
          </h2>
          <form className="" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-600 text-sm font-medium"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                required
                onChange={handleChange}
                placeholder="Enter email address"
                className="mt-1 p-2 w-full border rounded-md"
              />
            </div>
            <button
              type="submit"
              className="bg-primary w-full text-white p-2 rounded-md hover:bg-secondary"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Submit"}
            </button>
            <div className="mt-4 text-center">
              <Link to="/auth/login" className="text-primary hover:underline">
                Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
