import { ToastContainer } from "react-toastify";
import Pages from "./routes";
import { UserProvider } from "./context/UserProvideContext";
import { APIProvider } from "@vis.gl/react-google-maps";
import InstallPrompt from "./components/InstallPrompt";
import { useEffect, useState } from "react";
const apiKey = import.meta.env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY || "";

type BeforeInstallPromptEvent = Event & {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: "accepted" | "dismissed" }>;
};

function App() {
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  const isDesktop = () => {
    const userAgent = navigator.userAgent;
    return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
  };

  useEffect(() => {
    if (isDesktop()) {
      // Listen for the beforeinstallprompt event only on desktop
      const handleBeforeInstallPrompt = (e: Event) => {
        e.preventDefault();
        setDeferredPrompt(e as BeforeInstallPromptEvent);
        setShowInstallPrompt(true); // Show the InstallPrompt modal or banner
      };

      window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

      return () => {
        window.removeEventListener(
          "beforeinstallprompt",
          handleBeforeInstallPrompt
        );
      };
    }
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Show the browser's native install prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null); // Reset the deferred prompt
      });
    }
    setShowInstallPrompt(false); // Hide your custom prompt
  };

  return (
    <APIProvider
      apiKey={apiKey}
      onLoad={() => console.log("Maps API has loaded.")}
    >
      <UserProvider>
        <ToastContainer />
        <Pages />
        {showInstallPrompt && (
          <InstallPrompt
            onInstallClick={handleInstallClick}
            onClose={() => setShowInstallPrompt(false)}
          />
        )}
      </UserProvider>
    </APIProvider>
  );
}

export default App;
