import React, { useContext } from "react";
import { navlinks } from "../datas";
import { NavLink, Link } from "react-router-dom";
import {
  FaHome,
  FaUsers,
  FaMapMarkedAlt,
  FaPlus,
  FaUser,
  FaSignOutAlt,
  FaUserFriends,
  FaAmazonPay,
} from "react-icons/fa";
import { RxCross2, RxDashboard } from "react-icons/rx";

import { GrPlan } from "react-icons/gr";
import { logoutHandler } from "../../../auth/logout";
import { MdFolderShared, MdOutlineWorkHistory } from "react-icons/md";
import { PiStudentBold } from "react-icons/pi";
import { UserContext } from "../../../context/UserProvideContext";
import { useMediaQuery } from "react-responsive";

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const icons: Record<string, React.ReactNode> = {
    "/account/home": <FaHome size={isMobile ? 20 : 25} />,
    "/account/feedbacks": <FaUsers size={isMobile ? 20 : 25} />,
    "/account/trip-history": <FaMapMarkedAlt size={isMobile ? 20 : 25} />,
    "/account/schedule-journey": <GrPlan size={20} />,
    "/account/scheduled-history": <GrPlan size={20} />,
    "/account/instant-trip": <FaPlus size={isMobile ? 20 : 25} />,
    "/account/overview": <RxDashboard size={isMobile ? 20 : 25} />,
    "/account/profile": <FaUser size={isMobile ? 20 : 25} />,
    "/account/property": <MdFolderShared size={isMobile ? 20 : 25} />,
    "/account/payment-options": <FaAmazonPay size={isMobile ? 20 : 25} />,
    "/account/student": <PiStudentBold size={isMobile ? 20 : 25} />,
    "/account/job": <MdOutlineWorkHistory size={isMobile ? 20 : 25} />,
    "/account/referrals": <FaUserFriends size={isMobile ? 20 : 25} />,
  };
  const { userDetails } = useContext(UserContext);

  return (
    <div
      className={`fixed flex flex-col gap-8 h-full w-58 md:w-72 bg-primary text-white py-2 px-3 md:px-6  transition-transform transform overflow-y-auto  z-50 ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      }`}
      style={{ maxHeight: "100vh" }}
    >
      <div className="flex flex-row items-center justify-between border-b-2">
        <Link
          to="/"
          className={` text-white  text-2xl md:text-3xl lg:text-4xl  font-bold uppercase flex items-center space-x-2`}
        >
          <img src="/images/gswift.svg" className=" h-20" alt="" />
        </Link>
        <button className="block lg:hidden" onClick={onClose}>
          <RxCross2 />
        </button>
      </div>
      <ul className="flex flex-col gap-4 text-bodyText">
        {navlinks.map((link) => (
          <NavLink
            key={link.route}
            className={`flex flex-row gap-5 items-center px-2 py-4 md:p-4 text-white rounded-lg  hover:bg-primary2 cursor-pointer `}
            to={link.route}
            onClick={isMobile ? onClose : undefined}
            style={({ isActive }) => {
              return {
                backgroundColor: isActive ? "#698BAA" : "",
              };
            }}
          >
            <span className="mr-2">{icons[link.route]}</span>
            <span className=" text-base">{link.label}</span>
          </NavLink>
        ))}
      </ul>
      <Link
        className={`flex flex-row justify-center items-center p-4 text-white rounded-lg  ${
          userDetails?.isVerify ? "bg-green-500" : "bg-red-800"
        } transition-all duration-200`}
        to={"/account/profile"}
      >
        {userDetails?.isVerify ? "Verified" : "Pending verification"}
      </Link>
      <NavLink
        className={`flex flex-row gap-5 items-center p-4 text-white rounded-lg  hover:bg-primary2 cursor-pointer `}
        onClick={() => {
          logoutHandler();
          if (isMobile) onClose();
        }}
        to="/"
      >
        <span className="mr-2">
          <FaSignOutAlt size={25} />
        </span>
        <span>Logout</span>
      </NavLink>
    </div>
  );
};

export default Sidebar;
