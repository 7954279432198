import React, { useEffect, useState } from "react";
import { cookies } from "../utils/cookies";
import fetchApi from "../services/query/query";
import { UserDetails } from "../types";

interface UserContextType {
  userDetails: UserDetails | undefined;
  updateUserDetails: (details: UserDetails | undefined) => void;
}

export const UserContext = React.createContext<UserContextType>({
  userDetails: undefined,
  updateUserDetails: () => {},
});

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userDetails, setUserDetails] = useState<UserDetails | undefined>(
    undefined
  );
  const token = cookies.get("token");
  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetchApi("GET", "getProfile", undefined, token);
        setUserDetails(response[0]);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    getProfile();
  }, [token]);

  const updateUserDetails = (value: UserDetails | undefined) => {
    setUserDetails(value);
  };

  return (
    <UserContext.Provider value={{ userDetails, updateUserDetails }}>
      {children}
    </UserContext.Provider>
  );
};
