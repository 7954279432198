export const navlinks = [
  { route: "/account/home", label: "Home" },
  { route: "/account/overview", label: "Overview" },
  { route: "/account/feedbacks", label: "Feedbacks" },

  { route: "/account/instant-trip", label: "Instant Trip" },
  { route: "/account/trip-history", label: "Trip History" },

  { route: "/account/schedule-journey", label: "Schedule Journey" },
  { route: "/account/scheduled-history", label: "Schedule History" },

  { route: "/account/property", label: "Property" },
  { route: "/account/job", label: "Job" },
  { route: "/account/student", label: "Student" },
  { route: "/account/payment-options", label: "Payment" },

  { route: "/account/profile", label: "Update Profile" },
  { route: "/account/referrals", label: "Refer a Friend" },
];

export const details = [
  {
    type: "property",
    label: "Property Details",
    question: "Do you have a Property",
    modal: [
      {
        name: "type",
        type: "select",
        label: "Property Type",
        option: ["Car", "Van"],
      },
      {
        name: "model",
        type: "select",
        label: "Property Model",
        option: ["Toyota", "Camry", "Lexus", "Venza", "Corolla", "Mazda"],
      },
      {
        name: "registration_no",
        type: "input",
        label: "Registration No",
      },
      { name: "license_no", type: "input", label: "License No" },
    ],
  },
  {
    type: "job",
    label: "Job Details",
    question: "Are you employed",
    modal: [
      {
        name: "sector",
        type: "select",
        label: "Job Sector",
        option: ["public sector", "industry", "IT"],
      },
      {
        name: "job_title",
        type: "input",
        label: "Job Title",
      },
      {
        name: "license_no",
        type: "input",
        label: "License No",
      },
    ],
  },
  {
    type: "student",
    label: "Student Details",
    question: "Are you a student",
    modal: [
      {
        name: "institution",
        type: "select",
        label: "Institution",
        option: ["obafemi awolowo university", "university of ibadan"],
      },
      {
        name: "student_no",
        type: "input",
        label: "Student No",
      },
      { name: "course", type: "input", label: "Course" },
    ],
  },
];
