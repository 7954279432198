import React from "react";

interface MessageIconProps {
  onClick: () => void;
}

const MessageIcon: React.FC<MessageIconProps> = ({ onClick }) => {
  return (
    <div className="message" onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width="108"
        height="108"
      >
        <path
          fill="#e53e3e"
          d="M447.372,413.112l-17.544-52.629c-1-3.002-0.674-6.23,0.775-9.043c13.184-25.615,18.629-55.858,12.925-87.736c-8.746-48.868-44.53-89.475-90.943-105.048c11.976,28.924,15.628,61.084,9.645,92.984c-12.155,64.837-64.074,116.246-129.191,127.924c-8.429,1.512-16.945,2.248-25.396,2.463c19.776,21.336,46.173,36.503,75.619,41.784c31.682,5.682,61.784,0.393,87.322-12.594c2.802-1.425,6.007-1.742,8.99-0.748l52.83,17.61C441.655,431.163,450.456,422.363,447.372,413.112z"
          className="color173042 svgShape"
        ></path>
        <path
          fill=" #001429"
          d="M177.161,85.744C122.283,95.941,78.306,140.526,68.472,195.47c-5.704,31.878-0.259,62.121,12.925,87.736c1.449,2.813,1.775,6.041,0.776,9.043l-17.545,52.629c-3.083,9.251,5.717,18.051,14.968,14.968l52.83-17.61c2.984-0.994,6.19-0.677,8.991,0.748c25.537,12.987,55.638,18.276,87.321,12.594c54.809-9.829,99.281-53.698,109.541-108.428C356.357,150.736,273.546,67.834,177.161,85.744z M212.099,263.28h-68.234c-6.73,0-12.185-5.454-12.185-12.185c0-6.73,5.455-12.185,12.185-12.185h68.234c6.731,0,12.185,5.455,12.185,12.185C224.284,257.826,218.83,263.28,212.099,263.28z M260.838,214.541H143.864c-6.73,0-12.185-5.454-12.185-12.185c0-6.73,5.455-12.185,12.185-12.185h116.973c6.731,0,12.185,5.455,12.185,12.185C273.023,209.087,267.569,214.541,260.838,214.541z"
          className="color173042 svgShape"
        ></path>
      </svg>
    </div>
  );
};

export default MessageIcon;
