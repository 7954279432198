import { useState } from "react";
import MessageIcon from "./MessageIcon";
import FeedbackModal from "./Feedback";

function Feedbackindex() {
  const [modalVisible, setModalVisible] = useState(false);

  const handleIconClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div
      className=" fixed right-4 z-[400] bottom-4 
    "
    >
      <MessageIcon onClick={handleIconClick} />
      {modalVisible && <FeedbackModal onClose={handleCloseModal} />}
    </div>
  );
}

export default Feedbackindex;
