import Cookies from "js-cookie";

export const cookies = {
  set: (name: string, value: string) => Cookies.set(name, value),
  setJson: (name: string, value: object) => {
    const parsedValue = JSON.stringify(value);
    return Cookies.set(name, parsedValue);
  },
  get: (name: string) => Cookies.get(name),
  getJson: (name: string) => {
    const value = Cookies.get(name);
    return value ? JSON.parse(value) : value;
  },
  remove: (name: string) => Cookies.remove(name),
};
