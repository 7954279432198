import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import goo from "/images/goo.webp";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import PasswordToggle from "../../components/PasswordToggle";
import fetchApi from "../../services/query/query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { FaCheck, FaInfoCircle, FaTimes } from "react-icons/fa";

interface FormData {
  email: string;
  phone_number: string;
  password: string;
  password_confirmation: string;
  usertype: string;
  unique_code?: string;
}

const Signup: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [phoneValue, setPhoneValue] = useState<string | undefined>(undefined);

  const [isPasswordVisible, setIsPasswordVisible] = useState<{
    [key: string]: boolean;
  }>({
    password: false,
    password_confirmation: false,
  });
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    specialChar: false,
  });

  const handlePasswordToggle = (field: string) => {
    setIsPasswordVisible((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const validatePassword = (password: string) => {
    const length = password.length > 8;
    const uppercase = /[A-Z]/.test(password);
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setPasswordCriteria({
      length,
      uppercase,
      specialChar,
    });
  };

  const [formData, setFormData] = useState<FormData>({
    email: "",
    phone_number: "",
    password: "",
    usertype: "",
    password_confirmation: "",
  });
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const navigate = useNavigate();

  const handlePhoneChange = (value: string | undefined) => {
    const phoneValueString = value !== undefined ? value : "";
    setPhoneValue(phoneValueString);
    setFormData((prevData) => ({
      ...prevData,
      phone_number: phoneValueString,
    }));
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (name === "password") {
      validatePassword(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const signupUser = async () => {
    setIsLoading(true);
    try {
      const response = await fetchApi("POST", "register", formData);
      console.log(formData);
      console.log(response);
      if (!response.success) {
        toast.error(response.message);
        if (response.data && response.message) {
          setErrors(response.data);
        } else {
          setErrors({});
        }
      } else {
        toast.success(response.message);
        navigate("/auth/login");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    signupUser();
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get("referral_code");
    if (referralCode) {
      setFormData((prevData) => ({
        ...prevData,
        unique_code: referralCode,
      }));
    }
  }, []);
  return (
    <div
      className="min-h-screen"
      style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${goo})`,

        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-col items-center justify-center">
        <Header Sign_log="signup" isStatic={false} />
        <div className=" max-w-md my-[120px] w-full py-6 px-6 bg-white rounded-md shadow-md">
          <h2 className="text-2xl font-semibold mb-6 m-auto text-center">
            Signup
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-600 text-sm font-medium"
              >
                Email
              </label>
              <input
                value={formData.email}
                onChange={handleChange}
                type="email"
                id="email"
                name="email"
                placeholder="Enter email address"
                className="mt-1 p-2 w-full border rounded-md"
              />
              {errors.email && (
                <div className="text-red-500 text-sm mt-1">
                  {errors.email.slice(-1)}
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                htmlFor="phone_number"
                className="block text-gray-600 text-sm font-medium"
              >
                Phone
              </label>
              <PhoneInput
                international
                defaultCountry="US"
                value={phoneValue}
                onChange={handlePhoneChange}
                style={{
                  backgroundColor: "white",
                  border: " 1px solid #cccccc7b",
                  borderRadius: "4px",
                  padding: "8px",
                  width: "100%",
                  outline: "none",
                }}
              />
              {errors.phone_number && (
                <div className="text-red-500 text-sm mt-1">
                  {errors.phone_number.slice(-1)}
                </div>
              )}
            </div>

            <div className="flex flex-col ">
              {["password", "password_confirmation"].map((field) => (
                <div key={field} className="mb-4">
                  <label
                    htmlFor={field}
                    className="block text-gray-600 text-sm font-medium"
                  >
                    {field === "password" ? "Password" : "Confirm Password"}
                  </label>
                  <div
                    className="
              relative"
                  >
                    <input
                      type={isPasswordVisible[field] ? "text" : "password"}
                      id={field}
                      name={field}
                      placeholder={
                        field === "password"
                          ? "Enter your password"
                          : "Confirm your password"
                      }
                      value={formData[field as keyof FormData]}
                      onChange={handleChange}
                      className="mt-1 p-2 w-full border rounded-md"
                    />
                    <PasswordToggle
                      onToggle={() => handlePasswordToggle(field)}
                    />
                  </div>
                  {errors[field] && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors[field].slice(-1)}
                    </div>
                  )}
                  {field === "password" && (
                    <div className="text-sm text-gray-500 mt-2">
                      <div className="flex items-start font-semibold">
                        <FaInfoCircle className="mr-2 mt-1" /> Password must
                        include:
                      </div>
                      <ul className="list-disc pl-5 mt-1">
                        <li className="flex items-center">
                          {passwordCriteria.length ? (
                            <FaCheck className="text-green-500 mr-2" />
                          ) : (
                            <FaTimes className="text-red-500 mr-2" />
                          )}
                          More than 8 characters
                        </li>
                        <li className="flex items-center">
                          {passwordCriteria.uppercase ? (
                            <FaCheck className="text-green-500 mr-2" />
                          ) : (
                            <FaTimes className="text-red-500 mr-2" />
                          )}
                          Capital letters
                        </li>
                        <li className="flex items-center">
                          {passwordCriteria.specialChar ? (
                            <FaCheck className="text-green-500 mr-2" />
                          ) : (
                            <FaTimes className="text-red-500 mr-2" />
                          )}
                          Special characters
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="mb-4">
              <label
                htmlFor="unique_code"
                className="block text-gray-600 text-sm font-medium"
              >
                User Type{" "}
                <span className=" text-gray-400 italic">
                  (driver or passenger)
                </span>
              </label>

              <select
                id="usertype"
                name="usertype"
                className={`mt-1 p-2 w-full border rounded-md mb-0   px-6 border-gray-300 text-gray-900 text-sm appearance-none  `}
                value={formData.usertype}
                onChange={handleChange}
              >
                <option value=""></option>
                <option value={"passenger"}>Passenger</option>
                <option value={"driver"}>Driver</option>
              </select>
              {errors.usertype && (
                <div className="text-red-500 text-sm mt-1">
                  {errors.usertype.slice(-1)}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="unique_code"
                className="block text-gray-600 text-sm font-medium"
              >
                Referral Code <span className=" text-gray-400">(optional)</span>
              </label>

              <input
                value={formData.unique_code || ""}
                onChange={handleChange}
                type="text"
                id="unique_code"
                name="unique_code"
                placeholder="Enter referral code"
                className="mt-1 p-2 w-full border rounded-md"
              />
              {errors.unique_code && (
                <div className="text-red-500 text-sm mt-1">
                  {errors.unique_code.slice(-1)}
                </div>
              )}
            </div>
            <button
              type="submit"
              className="bg-primary w-full text-white p-2 rounded-md hover:bg-secondary"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Signup"}
            </button>
            <p className="text-center mt-4">
              Already have an account?{" "}
              <Link to="/auth/login" className="text-primary hover:underline">
                Login
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
