import { redirect } from "react-router-dom";
import { cookies } from "../utils/cookies";

export const getAuthToken = () => {
  const token = cookies.get("token");
  console.log("Token:", token);

  if (!token) {
    return null;
  }

  return token;
};

export const checkAuthLoader = () => {
  const loadToken = getAuthToken();
  const location = window.location;
  const searchParams = new URLSearchParams(location.search);
  const tripInviteParam = searchParams.get("tripId");

  if (!loadToken && tripInviteParam) {
    cookies.set("pendingTripId", location.search);
    return redirect("/auth/login");
  }
  if (!loadToken) {
    return redirect("/");
  }
  return loadToken;
};

export const checkAuthAdminLoader = () => {
  const loadToken = getAuthToken();
  const location = window.location;
  const searchParams = new URLSearchParams(location.search);
  const tripInviteParam = searchParams.get("tripId");

  if (!loadToken && tripInviteParam) {
    cookies.set("pendingTripId", location.search);
    return redirect("/auth/login");
  }
  if (!loadToken) {
    return redirect("/");
  }

  if (!loadToken) {
    return redirect("/");
  }

  return loadToken;
};

export const tokenLoader = () => {
  const loadToken = getAuthToken();
  return loadToken;
};
