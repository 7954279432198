import React, { Suspense } from "react";
import Spinner from "../Spinner";

const LazyLoader: React.FC = () => {
  return (
    <div className="w-full h-[70vh] md:h-[100vh] flex justify-center items-center">
      <Spinner />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const WithSuspense = (Component: React.FC) => (props?: any) => {
  return (
    <Suspense fallback={<LazyLoader />}>
      <Component {...props} />
    </Suspense>
  );
};

export default WithSuspense;
