import { useState, useEffect } from "react";
import { TfiMenu } from "react-icons/tfi";
import { VscAccount } from "react-icons/vsc";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import { navbarInfo } from "../pages/Home/components/constant";

interface Signlog {
  Sign_log: string;
  isStatic: boolean;
}

const Header: React.FC<Signlog> = ({ Sign_log, isStatic }) => {
  const [hidden, setHidden] = useState("false");
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`fixed top-0 z-[100] w-full px-8 sm:px-14 lg:px-32 py-6 flex flex-col transition-all duration-300 ${
        scrolling
          ? "bg-secondary shadow-lg"
          : ` ${
              isStatic
                ? " bg-secondary text-white "
                : "backdrop-blur-2xl  bg-opacity-70 text-white"
            }`
      }`}
    >
      <div className="flex items-center justify-between h-16">
        <Link
          to="/"
          className={`${
            scrolling ? "text-primary" : "text-white"
          } text-2xl md:text-3xl lg:text-4xl  font-bold uppercase flex items-center space-x-2`}
        >
          <img src="/images/gswift.svg" className=" h-20" alt="" />
        </Link>
        <nav className="hidden lg:flex space-x-8 items-center text-white">
          <ScrollLink smooth={true} duration={400} to="how">
            <Link to="/" className="py-2 px-4 hover:text-primary">
              How it Works
            </Link>
          </ScrollLink>
          <ScrollLink smooth={true} duration={400} to="service">
            <Link to="/" className="py-2 px-4 hover:text-primary">
              Services
            </Link>
          </ScrollLink>
          <ScrollLink smooth={true} duration={400} to="benefit">
            <Link to="/" className="py-2 px-4 hover:text-primary">
              Benefits
            </Link>
          </ScrollLink>
          <ScrollLink smooth={true} duration={400} to="contact">
            <Link to="/" className="py-2 px-4 hover:text-primary">
              Contact Us
            </Link>
          </ScrollLink>
        </nav>
        <div className="hidden lg:flex items-center space-x-4">
          <Link to="/auth/login">
            <button
              className={`flex items-center space-x-2 px-4 py-2   rounded-full ${
                Sign_log === "login"
                  ? "bg-primary text-white border-none"
                  : ` text-white hover:bg-primary hover:text-white hover:border-none ${
                      scrolling
                        ? "border border-white"
                        : "border text-white border-white"
                    }`
              } `}
            >
              <VscAccount className="text-xl" />
              <span>Login</span>
            </button>
          </Link>
          <Link to="/auth/signup">
            <button
              className={`px-4 py-2  rounded-full ${
                Sign_log === "signup"
                  ? "bg-primary text-white border-none"
                  : ` text-white hover:bg-primary hover:text-white hover:border-none ${
                      scrolling
                        ? "border border-white"
                        : "border text-white border-white"
                    }`
              } `}
            >
              SignUp
            </button>
          </Link>
        </div>
        <div className="lg:hidden">
          <TfiMenu
            onClick={() => setHidden(hidden === "false" ? "true" : "false")}
            className="text-2xl cursor-pointer"
          />
        </div>
      </div>

      {/* Mobile Menu */}
      <aside
        className={`fixed top-0 right-0 h-full w-64 bg-primary text-white transform transition-transform duration-300 z-40  ${
          hidden === "false" ? "translate-x-full" : "translate-x-0"
        }`}
      >
        <div className="flex flex-col h-svh px-4 py-5   bg-primary">
          <button
            onClick={() => setHidden("false")}
            className="self-end mb-4 text-2xl"
          >
            X
          </button>
          <div className="flex flex-col space-y-4  bg-primary">
            <Link
              to="/auth/login"
              className="w-full px-4 py-2 border rounded-full flex items-center space-x-2 hover:bg-primary transition-all"
            >
              <VscAccount className="text-xl" />
              <span>Login</span>
            </Link>
            <Link to="/auth/signup">
              <button className="w-full px-4 py-2 border rounded-full flex items-center space-x-2 hover:bg-primary transition-all">
                SignUp
              </button>
            </Link>
          </div>
          <ul className="flex-grow mt-8 space-y-4 z-50 relative  bg-primary">
            {navbarInfo.map((info) => (
              <li key={info.id} className="p-2 border-b border-gray-700">
                <ScrollLink
                  smooth={true}
                  duration={400}
                  to={info.link}
                  onClick={() => setHidden("false")}
                >
                  <Link to={`/`} className="hover:text-primary">
                    {info.label}
                  </Link>
                </ScrollLink>
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </header>
  );
};

export default Header;
