import React, { useState } from "react";
import Header from "../../layouts/Header";
import goo from "/images/goo.webp";
import fetchApi from "../../services/query/query";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import PasswordToggle from "../../components/PasswordToggle";

const ResetPassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    token: "",
    password: "",
    confirm_password: "",
  });
  const navigate = useNavigate();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState<{
    [key: string]: boolean;
  }>({
    password: false,
    password_confirmation: false,
  });
  const handlePasswordToggle = (field: string) => {
    setIsPasswordVisible((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    if (formData.password !== formData.confirm_password) {
      toast.error("Passwords do not match");
      setFormData({
        ...formData,
        password: "",
        confirm_password: "",
      });
      setIsLoading(false);
      return;
    }
    try {
      const response = await fetchApi("POST", "reset", {
        token: formData.token,
        password: formData.password,
      });
      console.log({ token: formData.token, password: formData.password });
      console.log(response);
      if (!response.success) {
        toast.error(response.message);
      } else {
        toast.success(response.message);
        navigate("/auth/login");
      }
    } catch (error) {
      console.error("Error occurred", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="min-h-screen "
      style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${goo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className=" flex flex-col items-center justify-center px-8 py-12">
        <Header Sign_log="login" isStatic={false} />
        <div className="max-w-md w-full mt-[200px]  p-6 bg-white rounded-md shadow-md">
          <h2 className="text-2xl font-semibold mb-6 text-center m-auto">
            Reset Password
          </h2>
          <form className="" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="token"
                className="block text-gray-600 text-sm font-medium"
              >
                Token
              </label>
              <input
                type="text"
                id="token"
                name="token"
                value={formData.token}
                onChange={handleChange}
                required
                placeholder="Enter token"
                className="mt-1 p-2 w-full border rounded-md"
              />
            </div>
            <div className="flex flex-col ">
              {["password", "confirm_password"].map((field) => (
                <div key={field} className="mb-4">
                  <label
                    htmlFor={field}
                    className="block text-gray-600 text-sm font-medium"
                  >
                    {field === "password" ? "Password" : "Confirm Password"}
                  </label>
                  <div
                    className="
              relative"
                  >
                    <input
                      type={isPasswordVisible[field] ? "text" : "password"}
                      id={field}
                      name={field}
                      placeholder={
                        field === "password"
                          ? "Enter your password"
                          : "Confirm your password"
                      }
                      value={formData[field as keyof typeof formData]}
                      onChange={handleChange}
                      required
                      className="mt-1 p-2 w-full border rounded-md"
                    />
                    <PasswordToggle
                      onToggle={() => handlePasswordToggle(field)}
                    />
                  </div>
                </div>
              ))}
            </div>
            <button
              type="submit"
              className="bg-primary w-full text-white p-2 rounded-md hover:bg-secondary"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Submit"}
            </button>
            <div className="mt-4 text-center">
              <Link to="/auth/login" className="text-blue-500 hover:underline">
                Back to Login
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
