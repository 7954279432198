import { redirect } from "react-router";
import { toast } from "react-toastify";
import fetchApi from "../services/query/query";
import { cookies } from "../utils/cookies";

export const logoutHandler = async () => {
  const token = cookies.get("token");

  try {
    const response = await fetchApi("GET", "logout", undefined, token);
    if (response.status === "success") {
      toast.success(response.message);
      cookies.remove("userDetails");
      cookies.remove("job");
      cookies.remove("student");
      cookies.remove("property");
      cookies.remove("token");
      redirect("/");
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    console.error("Error occurred during logout:", error);
  }
};
