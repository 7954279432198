import React from "react";

const Spinner: React.FC = () => {
  return (
    // <div
    //   className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-red-500 border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
    //   role="status"
    // ></div>
    <></>
  );
};

export default Spinner;
