import config from "../api/config";

type MethodTypes = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
const validateMethod = (method: string): method is MethodTypes => {
  return ["GET", "POST", "PUT", "PATCH", "DELETE"].includes(method);
};

export const fetchApiGeocode = async (postcode: string) => {
  const response = await fetch(
    `${config.baseUrlGeocode}?format=json&postalcode=${postcode}`
  );
  return response.json();
};

const fetchApi = async (
  method: MethodTypes,
  endpoint: string,
  body?: object,
  token?: string
) => {
  const headers = {
    ...(body instanceof FormData ? {} : { "Content-Type": "application/json" }),
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };
  if (!validateMethod(method)) {
    throw new Error("Invalid HTTP method");
  }

  // POSTCODE API INTEGRATION
  if (
    endpoint === "ops" &&
    method === "GET" &&
    (body as { postcode?: string })?.postcode
  ) {
    const url = `${config.baseUrl}/${endpoint}?postcode=${
      (body as { postcode?: string })?.postcode
    }`;
    const requestOptions = {
      method: "GET",
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    };

    const response = await fetch(url, requestOptions);
    return response.json();
  }
  //////////////////////////////

  const response = await fetch(`${config.baseUrl}/${endpoint}`, {
    method,
    headers,
    body: body instanceof FormData ? body : JSON.stringify(body),
  });
  return response.json();
};

export default fetchApi;
