import React, { ReactNode } from "react";

interface AdminCheckProps {
  children: ReactNode;
}

const AdminCheck: React.FC<AdminCheckProps> = ({ children }) => {
  const isAdmin = true;

  if (isAdmin) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default AdminCheck;
