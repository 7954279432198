import React, { ReactNode } from "react";
import { IoMenu } from "react-icons/io5";
interface TopbarProps {
  toggleSidebar: () => void;
  children?: ReactNode;
}

const Topbar: React.FC<TopbarProps> = ({ toggleSidebar, children }) => {
  return (
    <div className=" z-50 fixed h-20 bg-primary  w-full p-4 flex items-center justify-between">
      <div className="text-white cursor-pointer" onClick={toggleSidebar}>
        <IoMenu size={40} style={{ color: "white" }} />
      </div>

      <div className=" text-white">{children}</div>
    </div>
  );
};
export default Topbar;
