import { useState } from "react";
import Sidebar from "./components/Sidebar";
import { Outlet } from "react-router";
import { ScrollRestoration } from "react-router-dom";
import Topbar from "./components/Topbar";
import AdminCheck from "./AdminCheck";
import { useMediaQuery } from "react-responsive";

const AdminRoot = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isSidebarOpen, setIsSidebarOpen] = useState(isMobile ? false : true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <AdminCheck>
      <div className=" flex h-screen">
        <ScrollRestoration />
        <Topbar toggleSidebar={toggleSidebar}></Topbar>
        <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
        <div
          className={`flex-1 min-h-screen overflow-y-auto  bg-gray-100 mt-[5rem] ml-0 lg:ml-72`}
        >
          <Outlet />
        </div>
      </div>
    </AdminCheck>
  );
};
export default AdminRoot;
