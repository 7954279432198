import { lazy } from "react";
import WithSuspense from "../components/WithSuspense";

export const Home = WithSuspense(lazy(() => import("../pages/Home")));
export const ScheduleTrips = WithSuspense(
  lazy(() => import("../pages/ScheduleTrips"))
);

export const Profile = WithSuspense(
  lazy(() => import("../pages/Account/pages/Profile"))
);
export const Account = WithSuspense(lazy(() => import("../pages/Account")));
export const TripInstant = WithSuspense(
  lazy(() => import("../pages/Account/pages/TripInstant"))
);

export const TripHistory = WithSuspense(
  lazy(() => import("../pages/Account/pages/TripHistory"))
);
export const Feedbacks = WithSuspense(
  lazy(() => import("../pages/Account/pages/Feedbacks"))
);
export const ScheduleJourney = WithSuspense(
  lazy(() => import("../pages/Account/pages/ScheduleJourney"))
);
export const ScheduledHistory = WithSuspense(
  lazy(() => import("../pages/Account/pages/ScheduledHistory"))
);
export const EachScheduledHistory = WithSuspense(
  lazy(
    () => import("../pages/Account/pages/ScheduledHistory/EachScheduleHistory")
  )
);
export const Property = WithSuspense(
  lazy(() => import("../pages/Account/pages/Property"))
);
export const Student = WithSuspense(
  lazy(() => import("../pages/Account/pages/Student"))
);
export const Job = WithSuspense(
  lazy(() => import("../pages/Account/pages/Job"))
);
export const Payment = WithSuspense(
  lazy(() => import("../pages/Account/pages/Payment"))
);

export const TripAction = WithSuspense(
  lazy(() => import("../pages/Account/pages/TripAction"))
);

export const PaymentAction = WithSuspense(
  lazy(() => import("../pages/Account/pages/PaymentAction"))
);
export const Wallet = WithSuspense(
  lazy(() => import("../pages/Account/pages/Wallet"))
);

export const Overview = WithSuspense(
  lazy(() => import("../pages/Account/pages/Overview"))
);

export const Referrals = WithSuspense(
  lazy(() => import("../pages/Account/pages/Referrals"))
);

export const ScheduledTrips = WithSuspense(
  lazy(() => import("../pages/Account/pages/ScheduledTrips"))
);

export const EachScheduledTrip = WithSuspense(
  lazy(() => import("../pages/Account/pages/ScheduledTrips/EachScheduledTrip"))
);
// ADMIN ACCOUNT
export const AdminAccount = WithSuspense(
  lazy(() => import("../pages/AdminAccount"))
);

export const Trips = WithSuspense(
  lazy(() => import("../pages/AdminAccount/pages/Trips"))
);

export const Feedback = WithSuspense(
  lazy(() => import("../pages/AdminAccount/pages/Trips"))
);

export const ReferralSettings = WithSuspense(
  lazy(() => import("../pages/AdminAccount/pages/Referrals"))
);
