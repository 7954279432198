import React, { useState } from "react";
import PasswordToggle from "../../components/PasswordToggle";
import Header from "../../layouts/Header";
import goo from "/images/goo.webp";
import fetchApi from "../../services/query/query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { cookies } from "../../utils/cookies";
import { Link } from "react-router-dom";

interface FormData {
  email: string;
  password: string;
  rememberMe: boolean;
}
const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    email: cookies.get("rememberedEmail") || "",
    password: "",
    rememberMe: Boolean(cookies.get("rememberMe")) || true,
  });
  const handleRememberMe = () => {
    const { email, rememberMe } = formData;
    console.log(rememberMe, email);
    cookies.set("rememberedEmail", rememberMe ? email : "");
    cookies.set("rememberMe", rememberMe.toString());
  };

  const handlePasswordToggle = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const loginUser = async () => {
    setIsLoading(true);
    try {
      const response = await fetchApi("POST", "attemptLogin", formData);
      console.log(response);
      if (!response.success) {
        toast.error(response.message);
        if (response.data && response.message) {
          setErrors(response.data);
        } else {
          setErrors({});
        }
      } else {
        toast.success("Successful, Please insert OTP");
        navigate("/auth/otp-verify", { state: { email: formData.email } });
      }
    } catch (error) {
      console.error("Error ocurred", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginUser();
  };
  return (
    <div
      className="min-h-screen  "
      style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${goo})`,

        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className=" flex flex-col items-center justify-center px-8 py-12">
        <Header Sign_log="login" isStatic={false} />
        <div className="max-w-md w-full mt-[200px]  p-6 bg-white rounded-md shadow-md">
          <h2 className="text-2xl font-semibold mb-6 text-center m-auto">
            Login
          </h2>
          <form className="" onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-gray-600 text-sm font-medium"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter email address"
                className="mt-1 p-2 w-full border rounded-md"
              />
              {errors.email && (
                <div className="text-red-500 text-sm mt-1">
                  {errors.email.join(", ")}
                </div>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-gray-600 text-sm font-medium"
              >
                Password
              </label>
              <div
                className="
              relative"
              >
                <input
                  type={isPasswordVisible ? "text" : "password"}
                  id="password"
                  name="password"
                  onChange={handleChange}
                  placeholder="Enter your password"
                  className="mt-1 p-2 w-full border rounded-md"
                />{" "}
                <PasswordToggle onToggle={handlePasswordToggle} />
              </div>
              {errors.password && (
                <div className="text-red-500 text-sm mt-1">
                  {errors.password.join(", ")}
                </div>
              )}
            </div>
            <div className="flex justify-between items-center mt-6 mb-5">
              <span className="flex  items-center">
                <input
                  type="checkbox"
                  id="rememberMe"
                  checked={!formData.rememberMe}
                  onChange={() => {
                    handleRememberMe();
                    setFormData((prevData) => ({
                      ...prevData,
                      rememberMe: !prevData.rememberMe,
                    }));
                  }}
                  className="form-checkbox h-4 w-4 text-primary"
                />
                <label
                  htmlFor="rememberMe"
                  className="ml-2 text-sm text-gray-600"
                >
                  Remember me
                </label>
              </span>
              <Link
                to="/auth/forget-password"
                className="text-sm text-primary hover:underline"
              >
                Forgot password?
              </Link>
            </div>

            <button
              type="submit"
              className="bg-primary w-full text-white p-2 rounded-md hover:bg-secondary"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Login"}
            </button>
            <div className="mt-4 text-center">
              <span className="text-gray-600">Don't have an account?</span>
              <Link
                to="/auth/signup"
                className="text-primary hover:underline ml-1"
              >
                Register here
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
