interface InstallPromptProps {
  onInstallClick: () => void;
  onClose: () => void;
}

const InstallPrompt: React.FC<InstallPromptProps> = ({
  onInstallClick,
  onClose,
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 shadow-lg max-w-md mx-auto">
        <h2 className="text-xl font-bold mb-4">Install Our App</h2>
        <p className="text-gray-600 mb-4">
          Get the best experience by installing our app to your home screen.
        </p>
        <div className="mb-4">
          <p className="font-semibold">For iOS (Safari):</p>
          <p className="text-sm text-gray-500">
            Tap the Share button at the bottom of the screen, then tap "Add to
            Home Screen".
          </p>
        </div>
        <div>
          <p className="font-semibold">For Android (Chrome):</p>
          <p className="text-sm text-gray-500">
            Tap the three dots in the upper-right corner, then tap "Add to Home
            Screen".
          </p>
        </div>
        <button
          onClick={onInstallClick}
          className="mt-6 px-4 py-2 mr-4 bg-primary text-white rounded-lg hover:bg-primary2"
        >
          Install Now
        </button>
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
        >
          Maybe Later
        </button>
      </div>
    </div>
  );
};

export default InstallPrompt;
