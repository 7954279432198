export const navbarInfo = [
  {
    id: 1,
    link: "home",
    label: "Home",
  },
  {
    id: 2,
    link: "how",
    label: "How it works",
  },
  {
    id: 3,
    link: "service",
    label: "Services",
  },
  {
    id: 4,
    link: "benefit",
    label: "Benefits",
  },
  {
    id: 5,
    link: "contact",
    label: "Contact Us",
  },
];
