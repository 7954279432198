import React, { useState, useRef, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import goo from "/images/goo.webp";
import fetchApi from "../../services/query/query";
import { toast } from "react-toastify";
import { cookies } from "../../utils/cookies";
import Header from "../../layouts/Header";
import { UserContext } from "../../context/UserProvideContext";

const OtpVerify = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState<string[]>(new Array(6).fill(""));
  const otpInputs = useRef<HTMLInputElement[]>([]);
  const [countdown, setCountdown] = useState(300);
  const location = useLocation();
  console.log(location);
  const email = (location?.state as { email: string })?.email || "";
  const { userDetails, updateUserDetails } = useContext(UserContext);

  useEffect(() => {
    if (!email) {
      toast.error("Session has expired. Please return to the login page.");
      navigate("/auth/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 0) {
          clearInterval(timer);
          navigate("/auth/login");
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    otpInputs.current[0]?.focus();
  }, []);

  const handleInputChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    console.log(newOtp);
    otp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < otp.length - 1) {
      otpInputs.current[index + 1]?.focus();
    }
    if (!newOtp.includes("") && newOtp.length === 6) {
      handleSubmit();
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace" && index > 0) {
      e.preventDefault();
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      otpInputs.current[index - 1]?.focus();
    } else if (e.key === "ArrowLeft" && index > 0) {
      otpInputs.current[index - 1]?.focus();
    } else if (e.key === "ArrowRight" && index < otp.length - 1) {
      otpInputs.current[index + 1]?.focus();
    }
  };

  const handleSubmit = async () => {
    console.log(otp);
    const enteredOtp = otp.join("");
    console.log(enteredOtp);
    const formData = { email, otp: enteredOtp };
    console.log(formData);
    try {
      const response = await fetchApi("POST", "loginViaOtp", formData);
      console.log(response);
      if (response.success) {
        const { token } = response.data as {
          token: string;
        };

        try {
          const userProfile = await fetchApi(
            "GET",
            "getProfile",
            undefined,
            token
          );
          updateUserDetails(userProfile[0]);
          cookies.set("token", token);
          toast.success("OTP verified successfully");
          const userType = userProfile[0]?.usertype;
          console.log(userType, userProfile[0]);
          if (userType === "Admin") {
            navigate("/adminAccount/feedbacks");
          } else {
            const pendingTripId = cookies.get("pendingTripId");
            const scheduledJourneyId = cookies.get("scheduledJourneyId");
            if (pendingTripId) {
              cookies.remove("pendingTripId");
              navigate(`/account/trip_invite/${pendingTripId}`);
            } else if (scheduledJourneyId) {
              cookies.remove("scheduledJourneyId");
              navigate(`/account/scheduled-history/${scheduledJourneyId}`);
            } else {
              if (userDetails?.isVerify) {
                navigate("/account/profile");
              } else {
                navigate("/account/home");
              }
            }
          }
        } catch (error) {
          console.error("Error fetching profile:", error);
        }
      } else {
        toast.error("An Invalid OTP was entered. Please try again.");
      }
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const maskEmail = (email: string) => {
    const [localPart, domain] = email.split("@");
    const maskedLocalPart = localPart.slice(0, 1) + "*****";
    return `${maskedLocalPart}@${domain}`;
  };

  return (
    <div
      className="flex items-center justify-center h-screen "
      style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${goo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Header Sign_log="signup" isStatic={false} />
      <div className="flex rounded-xl flex-col justify-center item-center p-20 bg-white">
        <div className="text-center m-4">
          <p className="text-[30px] font-semibold">Verify your email</p>
          <p className="m-4">
            A 6-digit code has been sent to your email address{" "}
            {maskEmail(email)}
          </p>
          <p>
            Enter the otp code within{" "}
            <span className="font-semibold">
              {Math.floor(countdown / 60)}:{countdown % 60}
            </span>{" "}
            mins{" "}
          </p>
        </div>
        <div className="flex justify-center">
          {otp.map((inputRef, index) => (
            <input
              key={index}
              ref={(el) => (otpInputs.current[index] = el as HTMLInputElement)}
              type="text"
              maxLength={1}
              value={inputRef}
              inputMode="numeric"
              onChange={(e) => handleInputChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              className="max-w-12 border-primary h-12 text-2xl text-center border-4 rounded-md mx-2 focus:outline-none"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OtpVerify;
