import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  Home,
  Account,
  TripInstant,
  Feedbacks,
  Profile,
  Property,
  ScheduleJourney,
  Payment,
  TripAction,
  Trips,
  AdminAccount,
  Overview,
  PaymentAction,
  Job,
  Student,
  Referrals,
  ReferralSettings,
  ScheduleTrips,
  ScheduledHistory,
  TripHistory,
  EachScheduledHistory,
  ScheduledTrips,
  EachScheduledTrip,
  Wallet,
} from "./routes";
import Root from "../pages/Account/Root";
import Login from "../pages/Auth/Login";
import Signup from "../pages/Auth/Signup";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import OtpVerify from "../pages/Auth/OtpVerify";
import { checkAuthAdminLoader, checkAuthLoader } from "../auth/auth";
import ResetPassword from "../pages/Auth/ResetPassword";
import AdminRoot from "../pages/AdminAccount/AdninRoot";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/scheduled-trips",
    element: <ScheduleTrips />,
  },
  {
    path: "/auth/login",
    element: <Login />,
  },
  {
    path: "/auth/signup",
    element: <Signup />,
  },
  {
    path: "/auth/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "/auth/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/auth/otp-verify",
    element: <OtpVerify />,
  },

  {
    path: "/",
    element: <Root />,
    loader: checkAuthLoader,
    children: [
      { index: true, path: "/account/home", element: <Account /> },
      { path: "/account/payment-options", element: <Payment /> },
      { path: "/account/wallet", element: <Wallet /> },
      { path: "/account/student", element: <Student /> },
      { path: "/account/job", element: <Job /> },
      { path: "/account/feedbacks", element: <Feedbacks /> },
      { path: "/account/scheduled-history", element: <ScheduledHistory /> },
      {
        path: "/account/scheduled-history/:id",
        element: <EachScheduledHistory />,
      },
      { path: "/account/instant-trip", element: <TripInstant /> },
      { path: "/account/trip-history", element: <TripHistory /> },
      { path: "/account/schedule-journey", element: <ScheduleJourney /> },
      { path: "/account/profile", element: <Profile /> },
      { path: "/account/property", element: <Property /> },
      {
        path: "/account/trip_invite",
        element: <TripAction />,
      },
      {
        path: "/account/payment-status",
        element: <PaymentAction />,
      },
      {
        path: "/account/overview",
        element: <Overview />,
      },
      {
        path: "/account/referrals",
        element: <Referrals />,
      },
      {
        path: "/account/scheduled-trips",
        element: <ScheduledTrips />,
      },
      {
        path: "/account/scheduled-trips/:id",
        element: <EachScheduledTrip />,
      },
    ],
  },
  {
    path: "/",
    element: <AdminRoot />,
    loader: checkAuthAdminLoader,
    children: [
      {
        index: true,
        path: "/adminAccount/feedbacks",
        element: <AdminAccount />,
      },
      { path: "/adminAccount/trips", element: <Trips /> },
      { path: "/adminAccount/referrals", element: <ReferralSettings /> },
    ],
  },
]);

const Pages = () => {
  return (
    <>
      <RouterProvider router={router}></RouterProvider>
    </>
  );
};
export default Pages;
