import React from "react";
import { navlinks } from "../datas";
import { NavLink } from "react-router-dom";
import { FaHome, FaUsers, FaSignOutAlt, FaUserFriends } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

import { logoutHandler } from "../../../auth/logout";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const icons: Record<string, React.ReactNode> = {
    "/adminAccount/feedback": <FaHome size={25} />,
    "/adminAccount/trips": <FaUsers size={25} />,
    "/adminAccount/referrals": <FaUserFriends size={25} />,
  };

  return (
    <div
      className={`fixed flex flex-col gap-8 h-full w-58 md:w-72 bg-primary text-white py-8 px-3 md:px-6  transition-transform transform overflow-y-auto  z-50  ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      }`}
      style={{ maxHeight: "100vh" }}
    >
      <div className="flex flex-row items-center justify-between border-b-2">
        <Link
          to="/"
          className={` text-white  text-2xl md:text-3xl lg:text-4xl  font-bold uppercase flex items-center space-x-2`}
        >
          Gswift
        </Link>
        <button className="block lg:hidden" onClick={onClose}>
          <RxCross2 />
        </button>
      </div>
      <ul className="flex flex-col gap-4 text-bodyText">
        {navlinks.map((link) => (
          <NavLink
            key={link.route}
            className={`flex flex-row gap-5 items-center px-2 py-4 md:p-4 text-white rounded-lg  hover:bg-primary2 cursor-pointer `}
            to={link.route}
            onClick={isMobile ? onClose : undefined}
            style={({ isActive }) => {
              return {
                backgroundColor: isActive ? "#698BAA" : "",
              };
            }}
          >
            <span className="mr-2">{icons[link.route]}</span>
            <span className=" text-base">{link.label}</span>
          </NavLink>
        ))}
      </ul>
      <NavLink
        className={`flex flex-row gap-5 items-center p-4 text-white rounded-lg  hover:bg-primary2 cursor-pointer `}
        onClick={() => {
          logoutHandler();
          if (isMobile) onClose();
        }}
        to="/"
      >
        <span className="mr-2">
          <FaSignOutAlt size={25} />
        </span>
        <span>Logout</span>
      </NavLink>
    </div>
  );
};

export default Sidebar;
