import React from "react";
import { IoMenu, IoCarSport, IoPerson } from "react-icons/io5";
import { Link } from "react-router-dom";
import { cookies } from "../../../utils/cookies";
import fetchApi from "../../../services/query/query";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

interface TopbarProps {
  toggleSidebar: () => void;
  userType: string | undefined;
  setUserType: (type: string) => void;
}

const Topbar: React.FC<TopbarProps> = ({
  toggleSidebar,
  userType,
  setUserType,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const handleUserTypeChange = async () => {
    const newUserType = userType === "passenger" ? "driver" : "passenger";
    console.log("clicked", newUserType);
    try {
      const payload = { usertype: newUserType };
      const token = cookies.get("token");
      const response = await fetchApi("PUT", "updateUsertype", payload, token);
      if (response.success) {
        setUserType(newUserType);
        toast.success(` Switched to ${newUserType}!`);
      } else {
        console.error("Error Switching modes");
        toast.error("Error Switching modes");
      }
    } catch (error) {
      console.error("An error occurred", error);
    }
  };

  return (
    <div className="z-50 fixed h-20 bg-primary w-full p-4 flex items-center justify-between">
      <div className="text-white cursor-pointer" onClick={toggleSidebar}>
        <IoMenu size={40} style={{ color: "white" }} />
      </div>
      <div
        className={`flex space-x-2 bg-tertiary p-1 rounded-full ${
          isMobile ? "ml-0 px-2" : " ml-[20%]"
        } font-bold`}
      >
        <button
          onClick={() => handleUserTypeChange()}
          className={`${
            userType === "passenger"
              ? "bg-primary2 text-white"
              : " text-primary2"
          } ${
            isMobile ? "px-1 py-1" : "px-2 py-2"
          }  rounded-full flex items-center`}
        >
          {isMobile ? <IoPerson size={20} /> : "Passenger"}
        </button>
        <button
          onClick={() => handleUserTypeChange()}
          className={`${
            userType === "driver" ? "bg-primary2 text-white" : " text-primary2"
          } ${
            isMobile ? "px-1 py-1" : "px-2 py-2"
          }  rounded-full flex items-center`}
        >
          {isMobile ? <IoCarSport size={20} /> : "Driver"}
        </button>
      </div>
      <Link to={"/account/scheduled-trips"} className="text-white">
        {isMobile ? "Trips" : "View Scheduled Trips around you"}
      </Link>
    </div>
  );
};

export default Topbar;
