import { useContext, useEffect, useState } from "react";
import Sidebar from "./components/Sidebar";
import { Outlet } from "react-router";
import { ScrollRestoration } from "react-router-dom";
import Topbar from "./components/Topbar";
import Feedbackindex from "./components/Feedback/Feedbackindex";
import fetchApi from "../../services/query/query";
import { cookies } from "../../utils/cookies";
import { UserContext } from "../../context/UserProvideContext";
import { useMediaQuery } from "react-responsive";

const Root = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isSidebarOpen, setIsSidebarOpen] = useState(isMobile ? false : true);
  const { userDetails, updateUserDetails } = useContext(UserContext);
  const [userType, setUserType] = useState(userDetails?.usertype);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = cookies.get("token");
        const userProfile = await fetchApi(
          "GET",
          "getProfile",
          undefined,
          token
        );
        const fetchedUserType = userProfile[0].usertype;
        setUserType(fetchedUserType);
        updateUserDetails(userProfile[0]);
        setRefreshKey((prevKey) => prevKey + 1);
      } catch (error) {
        console.error(" An error ocurred");
      }
    };

    fetchUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  return (
    <div className=" flex h-screen">
      <ScrollRestoration />
      <Topbar
        toggleSidebar={toggleSidebar}
        userType={userType}
        setUserType={setUserType}
      />
      <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
      <div
        className={`flex-1 min-h-screen overflow-y-auto  bg-gray-100 mt-[5rem] ml-0 lg:ml-72`}
      >
        <Outlet key={refreshKey} />
      </div>
      <Feedbackindex />
    </div>
  );
};
export default Root;
