import React, { useState, ChangeEvent } from "react";
import { cookies } from "../../../../utils/cookies";
import fetchApi from "../../../../services/query/query";
import { toast } from "react-toastify";

interface FeedbackModalProps {
  onClose: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ onClose }) => {
  const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);
  const [rating, setRating] = useState<number>(0);
  const [feedbackText, setfeedbackText] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const token = cookies.get("token");

  const emojis = ["Low 🙂", "Medium 😐", "High 😞"];

  const handleEmojiClick = (emoji: string) => {
    setSelectedEmoji(emoji);
  };

  const handleRatingChange = (value: number) => {
    setRating(value);

    setSelectedEmoji(null);
    const stars = document.querySelectorAll(".star");
    stars.forEach((star, index) => {
      if (index < value) {
        star.classList.add("text-yellow-500");
        star.classList.remove("text-gray-400");
      } else {
        star.classList.remove("text-yellow-500");
        star.classList.add("text-gray-400");
      }
    });
  };

  const handleSubmitFeedback = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    let severity: string;
    switch (selectedEmoji) {
      case "Low 🙂":
        severity = "low";
        break;
      case "Medium 😐":
        severity = "medium";
        break;
      case "High 😞":
        severity = "high";
        break;
      default:
        severity = "";
        break;
    }

    const feedbackPayload = {
      description: feedbackText,
      severity: severity,
      rating: rating.toString(),
    };

    try {
      setIsLoading(true);
      const response = await fetchApi(
        "POST",
        "feedback",
        feedbackPayload,
        token
      );

      console.log(response);
      if (response.message === "Feedback submitted successfully") {
        setIsLoading(false);
        setfeedbackText("");
        setSelectedEmoji(null);
        setRating(0);
        toast.success("Feedback submitted successfully");
      } else {
        toast.error(response.message);
        setIsLoading(false);
        setfeedbackText("");
        setSelectedEmoji(null);
        setRating(0);
      }
    } catch (error) {
      console.error("Error fetching feedbacks:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modal   fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-40 flex items-center justify-center">
      <div className="flex flex-col modal-content  e border bg-white rounded-md  w-full max-w-md p-2">
        <div className="flex flex-col bg-primary p-4  ">
          <div className="rounded-xl text-white bg-primary2 p-3">
            <div className="flex flex-row  justify-between">
              <span className=" font-bold text-xl">Hi</span>
              <span
                className="close font-bold text-2xl cursor-pointer"
                onClick={onClose}
              >
                &times;
              </span>
            </div>
            <h2 className="text-xl font-bold ">
              Ask us anything, <br /> share your feedback
            </h2>
          </div>
        </div>

        <div className="min-h-[70%] flex flex-col p-2 ">
          <form action="" onSubmit={handleSubmitFeedback}>
            <div className="flex flex-col mt-auto ">
              <div className=" justify-between flex flex-col">
                <div className="flex flex-row items-center">
                  <div className="mt-2 mr-2 font-semibold text-base">Rate:</div>
                  <div className="flex flex-row">
                    {[1, 2, 3, 4, 5].map((value) => (
                      <div
                        key={value}
                        className={`font-bold text-md text-3xl mt-1 focus:outline-none star ${
                          rating >= value ? "text-yellow-500" : "text-gray-400"
                        }`}
                        onClick={() => {
                          handleRatingChange(value);
                        }}
                      >
                        &#9733;
                      </div>
                    ))}
                  </div>
                </div>
                <div className="text-xl flex-row flex items-center">
                  <div className=" font-semibold text-base ">
                    {" "}
                    Severity Level:
                  </div>{" "}
                  <div className=" ">
                    <div className="flex flex-row items-center">
                      {emojis.map((emoji) => (
                        <div
                          key={emoji}
                          className={`emoji flex p-1 rounded-xl   border-2 m-1 flex-row text-center  cursor-pointer font-semibold text-base${
                            selectedEmoji === emoji
                              ? "bg-gray-800 text-white"
                              : ""
                          }`}
                          onClick={() => {
                            handleEmojiClick(emoji);
                          }}
                        >
                          {emoji}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <textarea
                id="feedback-text"
                className="w-full h-20 border border-gray-300 rounded-md p-2 mb-4"
                placeholder="Tell us what you thought (optional)"
                value={feedbackText}
                onChange={(e) => setfeedbackText(e.target.value)}
              />
              <button
                type="submit"
                id="submit-feedback"
                className="bg-primary text-white py-2 px-4 rounded-md hover:bg-primary2 focus:outline-none focus:shadow-outline-red"
              >
                {isLoading ? "Loading..." : "Submit Feedback"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
